import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { withPrefix, graphql } from 'gatsby';
import supportingLogo from '../images/source-separation-systems-australian-flora-foundation.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import {
  MAX_WIDTH,
  MAX_WIDTH_SITE,
  screen,
} from '../components/common/variables';

const ResearchSection = styled.div`
  background: #fafafa;
  width: 100%;

  .rs-wrapper {
    max-width: ${MAX_WIDTH};
    padding: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media ${screen.large} {
      flex-wrap: nowrap;
    }
    @media ${screen.largeXL} {
      padding: 90px;
    }

    .left-description {
      max-width: 620px;
    }

    .texts-container {
    }

    .logo-container {
      order: -1;
      text-align: center;
      width: 100%;
      @media ${screen.large} {
        order: 1;
        width: auto;
      }
      img {
        display: block;
        width: 290px;
        margin: 0 auto;
        @media ${screen.large} {
          width: 500px;
          height: 500px;
          margin: 0 20px 0 0;
        }
      }
    }
  }

  h1 {
    margin: 30px 0 20px 0;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.25;
    @media ${screen.large} {
      font-size: 2.4rem;
    }
  }

  h2 {
    font-size: 1.1rem;
    margin: 50px 0 20px 0;
  }

  .flex-wrapper {
    display: flex;
    margin: 30px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${screen.large} {
      flex-wrap: nowrap;
    }
  }

  h2 {
    margin-bottom: 30px;
  }

  p,
  span {
    color: #808080;
  }

  img {
    max-width: 200px;
    max-height: 200px;
  }

  h3 {
    color: #6cad3f;
    font-weight: 500;
    font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  }

  .image-container {
    flex: 0 0 30%;
    max-width: 200px;
    max-height: 200px;
    margin-right: 50px;
    margin-bottom: 1rem;
    @media ${screen.medium} {
      margin-bottom: 0;
    }
  }

  span {
    font-weight: 600;
  }

  p {
    margin: 15px 0px;
    font-size: 1.1rem;
  }
`;

const Banner = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  @media ${screen.largeXL} {
    padding-top: 0;
  }

  .small-banner {
    max-width: 100%;
    @media ${screen.largeXL} {
      display: none;
    }
  }

  .large-banner {
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto 45px auto;
  padding: 0 35px 0 35px;
  @media ${screen.largeXL} {
    margin: 0 auto 90px auto;
  }

  .flex-wrapper {
    display: flex;
    margin: 30px 0px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${screen.large} {
      flex-wrap: nowrap;
    }
  }

  h1 {
    margin: 30px 0 20px 0;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.25;
    @media ${screen.large} {
      font-size: 2.4rem;
    }
  }

  h2 {
    font-size: 1.1rem;
    margin: 50px 0 20px 0;
  }

  .header-text {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.25;
    margin: 40px 0;
    margin-bottom: 30px;
    @media ${screen.large} {
      font-size: 1.8rem;
      margin: 60px 0;
      margin-bottom: 30px;
    }
    color: #3a3a3a;
    font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
    max-width: 620px;
    p {
      margin: 2rem 0px;
      font-weight: 500 !important;
      line-height: 1.8rem;
    }
  }

  .main-text {
    max-width: 620px;
    margin-bottom: 70px;
    p {
      color: #808080;
      font-size: 1.1rem;
      margin: 1rem 0px;
    }
  }

  .planting-section {
    max-width: 620px;
    p {
      color: #808080;
      font-size: 1.1rem;
      margin: 1rem 0px;
    }
  }

  .flowers-section {
    h2 {
      margin-bottom: 30px;
    }
    p,
    span {
      color: #808080;
    }
    img {
      max-width: 200px;
      max-height: 200px;
    }
    h3 {
      color: #6cad3f;
      font-weight: 500;
      font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
    }

    .image-container {
      flex: 0 0 30%;
      max-width: 200px;
      max-height: 200px;
      margin-right: 50px;

      margin-bottom: 1rem;
      @media ${screen.medium} {
        margin-bottom: 0;
      }
    }

    span {
      font-weight: 600;
    }

    p {
      margin: 15px 0px;
      font-size: 1.1rem;
    }
  }
`;

const AboutPage = ({ data }) => {
  const { acfNativeFlora } = data.content;
  const pageTitle =
    (acfNativeFlora.seo && acfNativeFlora.seo.page_title) || 'About';
  const pageDescription = acfNativeFlora.seo && acfNativeFlora.seo.description;

  return (
    <Layout>
      <SEO
        title={pageTitle}
        description={pageDescription}
        image={withPrefix('/og_images/about.jpeg')}
      />
      <Banner>
        <GatsbyImage
          className="large-banner"
          image={
            data.content.acfNativeFlora.imageBanner.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={
            data.content.acfNativeFlora.imageBanner.altText ||
            'Source Separation Systems'
          }
        />

        <div className="small-banner">
          <GatsbyImage
            image={
              data.smallScreenBanner.acfNativeFlora.imageBanner.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={
              data.smallScreenBanner.acfNativeFlora.imageBanner.altText ||
              'Source Separation Systems'
            }
          />
        </div>
      </Banner>
      <Wrapper>
        <div className="header-text">{acfNativeFlora.headerText}</div>
        <div
          className="main-text"
          dangerouslySetInnerHTML={{ __html: acfNativeFlora.mainText }}
        />
        <div className="planting-section">
          <h1
            dangerouslySetInnerHTML={{
              __html: acfNativeFlora.plantingSection.title,
            }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: acfNativeFlora.plantingSection.text,
            }}
          />
        </div>
        <div className="flowers-section">
          <h2>{acfNativeFlora.flowersSection.title}</h2>
          {acfNativeFlora.flowersSection.flowers.map((flower, i) => (
            <div key={i} className="flex-wrapper">
              <div className="image-container">
                <GatsbyImage
                  image={flower.image.localFile.childImageSharp.gatsbyImageData}
                  alt={flower.title}
                />
              </div>
              <div className="text-container">
                <h3>{flower.title}</h3>
                <p>
                  <span>Botanical Name:</span> {flower.botanicalName}
                </p>
                <p>{flower.description}</p>
                <p>
                  <span>Classification:</span> {flower.classification}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
      <ResearchSection>
        <div className="rs-wrapper">
          <div className="texts-container">
            <h1>{acfNativeFlora.researchSection.title}</h1>
            <div className="left-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: acfNativeFlora.researchSection.description,
                }}
              />
            </div>
            <h2>
              At present we are supporting the following research programs:
              {acfNativeFlora.researchSection.researchPrograms.length >= 1 &&
                acfNativeFlora.researchSection.researchPrograms.map(
                  (program, i) => (
                    <div key={i} className="flex-wrapper">
                      <div className="image-container">
                        <GatsbyImage
                          image={
                            program.image.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={program.title}
                        />
                      </div>
                      <div className="text-container">
                        <h3>{program.title}</h3>

                        <p>{program.description}</p>
                      </div>
                    </div>
                  )
                )}
            </h2>
          </div>
          <div className="logo-container">
            <img src={supportingLogo} alt="Supporting Australian Research" />
          </div>
        </div>
      </ResearchSection>
    </Layout>
  );
};

export default AboutPage;

export const aboutData = graphql`
  {
    content: wpPage(id: { eq: "cG9zdDoyMTAy" }) {
      content
      acfNativeFlora {
        seo {
          pageTitle
          description
        }
        headerText
        mainText
        plantingSection {
          title
          text
        }
        flowersSection {
          title
          flowers {
            title
            botanicalName
            description
            classification
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
                }
              }
            }
          }
        }
        researchSection {
          title
          description
          researchPrograms {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
                }
              }
            }
          }
        }
        imageBanner {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    smallScreenBanner: wpPage(id: { eq: "cG9zdDoyMTAy" }) {
      acfNativeFlora {
        imageBanner {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1080, height: 624)
            }
          }
        }
      }
    }
  }
`;
